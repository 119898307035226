/*
 * Comcast CONFIDENTIAL
 *
 * Copyright 2003 - 2017 Comcast Corporation
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Comcast Corporation and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to Comcast Corporation
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is unlawful and strictly forbidden unless prior written permission is obtained
 * from Comcast Corporation.
 */

declare var angular: angular.IAngularStatic;

'use strict';
//// CONTROLLER ////
export class RemoveOrderGroupDialogController {
    public selectAll: any;

    static get $inject() {
        return [
            '$state',
            '$mdDialog',
            'NotificationService',
            'OrderResourceFactory',
            'orderId',
            'orderGroups',
        ];
    }

    constructor(
        public $state: any,
        public $mdDialog: any,
        public NotificationService: any,
        public OrderResourceFactory: any,
        public orderId: any,
        public orderGroups: any
    ) {
        /* BINDABLE: DATA */
    }

    //START FUNCTION BLOCK
    cancel() {
        let vm = this;
        vm.$mdDialog.cancel();
    }

    remove() {
        let vm = this;
        // Do removal
        var groupsToRemove = vm.orderGroups
            .filter(function (group:any) {
                return group.selected;
            })
            .map(function (group:any) {
                return group.id;
            });

        vm.OrderResourceFactory.removeOrderGroups(
            { id: vm.orderId, groupsToRemove },
            function () {
                vm.NotificationService.showNotificationToast('Successfully deleted order group(s)');
                // Return the remaining groups
                vm.$mdDialog.hide(
                    vm.orderGroups.filter(function (group:any) {
                        return groupsToRemove.indexOf(group.id) < 0;
                    })
                );
            },
            function failure(reason:any) {
                vm.NotificationService.showNotificationToast(
                    'Failed to delete order group(s)',
                    reason.data
                );
                vm.$mdDialog.cancel();
            }
        );
    }

    disableRemove() {
        let vm = this;
        return (
            vm.orderGroups.filter(function (group:any) {
                return group.selected;
            }).length < 1
        );
    }

    toggleAll() {
        let vm = this;
        var toggleStatus = vm.selectAll;
        angular.forEach(vm.orderGroups, function (itm) {
            itm.selected = toggleStatus;
        });
    }

    optionToggled() {
        let vm = this;
        vm.selectAll = vm.orderGroups.every(function (itm:any) {
            return itm.selected;
        });
    }

    $onInit() {}
}
