/*
 * Comcast CONFIDENTIAL
 *
 * Copyright 2003 - 2018 Comcast Corporation
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Comcast Corporation and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to Comcast Corporation
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is unlawful and strictly forbidden unless prior written permission is obtained
 * from Comcast Corporation.
 */
//// CONTROLLER ////
export class saveBeforeExportDialogController {
    static get $inject() {
        return [
            '$mdDialog',
        ];
    }

    constructor(
        public $mdDialog: any,
    ) {
        /* BINDABLE: DATA */
    }

    $onInit() {}

    cancel() {
        this.$mdDialog.cancel();
    }

    saveAndExport() {
        this.$mdDialog.hide(true);
    }

    onlyExport() {
        this.$mdDialog.hide(false);
    }
}
