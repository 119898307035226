/*
 * Comcast CONFIDENTIAL
 *
 * Copyright 2003 - 2017 Comcast Corporation
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Comcast Corporation and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to Comcast Corporation
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is unlawful and strictly forbidden unless prior written permission is obtained
 * from Comcast Corporation.
 */

//// CONTROLLER ////
'use strict';
export class QuickAddSpotDialogController {
    // Public bindable properties
    public allSelected: any;

    // Injectable values
    static get $inject() {
        return [
            '$mdDialog',
            'notFoundSpots'
        ];
    }

    constructor(
        public $mdDialog: any,
        public notFoundSpots: any
    ) {
        let vm = this;
        /* BINDABLE: DATA */
        vm.allSelected = false;
    }

    //START FUNCTION BLOCK
    cancel() {
        let vm = this;
        vm.$mdDialog.cancel();
    }

    submit() {
        let vm = this;
        let selectedSpots:any = [];
        vm.notFoundSpots.map((spot:any) => {
            if (spot.selected) {
                selectedSpots.push(spot);
            }
            return spot;
        });
        vm.$mdDialog.hide(selectedSpots);
    }

    selectAll() {
        let vm = this;
        if (vm.allSelected) {
            for (let i = 0; i < vm.notFoundSpots.length; i++) {
                vm.notFoundSpots[i].selected = true;
            }
        } else {
            for (let i = 0; i < vm.notFoundSpots.length; i++) {
                vm.notFoundSpots[i].selected = false;
            }
        }
    }

    $onInit() {
        //Do init things
    }
}
