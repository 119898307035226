/*
 * Comcast CONFIDENTIAL
 *
 * Copyright 2003 - 2017 Comcast Corporation
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Comcast Corporation and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to Comcast Corporation
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is unlawful and strictly forbidden unless prior written permission is obtained
 * from Comcast Corporation.
 */

import * as moment from 'moment-timezone';

'use strict';
//// CONTROLLER ////
export class SubmitLaterDialogController {
    public dateOpen: boolean = false;
    public delayedTime: any;
    public delayedDate: any;
    public ampm: any;

    static get $inject() {
        return ['$mdDialog', 'order'];
    }

    constructor(
        public $mdDialog: any,
        public order: any
    ) {
        let vm = this;
        /* BINDABLE: DATA */
        vm.delayedTime;
        vm.delayedDate;
        vm.ampm;
    }

    //START FUNCTION BLOCK
    cancel() {
        let vm = this;
        vm.$mdDialog.cancel();
    }

    ok() {
        let vm = this;
        var delayedStartTime = moment(vm.delayedDate).set(
            'hour',
            vm.ampm == 'am'
                ? vm.delayedTime == 12
                    ? 0
                    : vm.delayedTime
                : vm.delayedTime == 12
                ? vm.delayedTime
                : vm.delayedTime + 12
        );
        if (!delayedStartTime || !delayedStartTime.isValid) {
            vm.$mdDialog.show(
                vm.$mdDialog
                    .alert()
                    .clickOutsideToClose(true)
                    .title('Invalid Date')
                    .textContent('Date entered is not valid.')
                    .ariaLabel('Date entered is not valid.')
                    .ok('OK')
                    .multiple(true)
            );
        } else {
            vm.$mdDialog.hide(delayedStartTime.toString());
        }
    }

    //This does the datepicker validation
    dateFilter(date:any) {
        let validating = moment(date);

        return (
            validating.isSameOrAfter(moment(), 'day') &&
            validating.isSameOrBefore(moment().add(5, 'days'), 'day')
        );
    }

    // This helps validate everything else WITH the date picker
    dateNotValid() {
        let vm = this;
        var validating = moment(vm.delayedDate);
        if (vm.ampm === 'pm') {
            validating.hour(parseInt(vm.delayedTime, 10) + 12);
        } else {
            validating.hour(parseInt(vm.delayedTime, 10));
        }

        return (
            validating.isSameOrBefore(moment(), 'hour') ||
            validating.isSameOrAfter(moment().add(5, 'days'), 'hour')
        );
    }

    $onInit() {
        let vm = this;
        //Do init things
        let hour = parseInt(moment().add(1, 'hour').hour(), 10);
        vm.delayedTime = hour > 12 ? hour - 12 : hour;
        vm.delayedDate = moment().add(1, 'hour').format('LL');
        vm.ampm = hour > 12 ? 'pm' : 'am';
    }
}
