/*
 * Comcast CONFIDENTIAL
 *
 * Copyright 2003 - 2017 Comcast Corporation
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Comcast Corporation and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to Comcast Corporation
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is unlawful and strictly forbidden unless prior written permission is obtained
 * from Comcast Corporation.
 */

//// CONTROLLER ////
export class OrderImportErrorDialogController {
    public destinations: any;
    public spots: any;
    public destText: any;
    public spotText: any;

    static get $inject() {
        return ['$mdDialog', 'message'];
    }

    constructor(
        public $mdDialog: any,
        public message: any) {
        let vm = this;
        /* BINDABLE: DATA */
        vm.destinations = [];
        vm.spots = [];
        vm.destText = null;
        vm.spotText = null;
    }

    //START FUNCTION BLOCK
    ok() {
        let vm = this;
        vm.$mdDialog.hide();
    }

    stripAndFormatMessage(string:any) {
        let vm = this;
        string = string.replace(/["]/g, '').split('\\n\\n');

        if (string[0] && string[2]) {
            vm.destText = string[0];
            vm.spotText = string[2];
            vm.destinations = string[1].split('\\n');
            vm.spots = string[3].split('\\n');
        } else if (string[0].indexOf('Destinations') > -1) {
            vm.destText = string[0];
            vm.destinations = string[1].split('\\n');
        } else if (string[0].indexOf('Spot\'s') > -1 || string[0].indexOf('Spots') > -1) {
            vm.spotText = string[0];
            vm.spots = string[1].split('\\n');
        }
    }

    $onInit() {
        let vm = this;
        //Do init things
        vm.stripAndFormatMessage(vm.message);
    }
}
