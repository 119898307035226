/*
 * Comcast CONFIDENTIAL
 *
 * Copyright 2003 - 2017 Comcast Corporation
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Comcast Corporation and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to Comcast Corporation
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is unlawful and strictly forbidden unless prior written permission is obtained
 * from Comcast Corporation.
 */

declare var angular: angular.IAngularStatic;

//// CONTROLLER ////
export class EmailNotificationsDialogController {
    public notificationEmails: any;
    public enteredEmails: any;
    public originalEmails: any;

    static get $inject() {
        return [
            '$state',
            '$mdDialog',
            'emails',
        ];
    }

    constructor(
        public $state: any,
        public $mdDialog: any,
        public emails: any
    ) {
        /* PRIVATE : DATA */
        //Declare all private variables here
        /*
        * This is the `vm` object. It is a direct reference to the controller
        * which acts as our 'view-model' in angular. It also limits our need
        * to be accessing $scope directly. */
        /*jshint validthis:true*/
        var vm = this;

        /* BINDABLE : DATA */
        vm.notificationEmails = [];
        vm.enteredEmails = '';
        vm.originalEmails = null;
    }

    /* IMPLEMENTATION : BINDABLE */
    cancel() {
        let vm = this;

        vm.$mdDialog.cancel();
    }

    addEmails() {
        let vm = this;
        
        if (vm.enteredEmails && vm.enteredEmails.length > 0) {
            var parsedEntry = vm.enteredEmails.trim().split(/\n+|\s+|,+|;+/);
            if (parsedEntry instanceof Array && parsedEntry.length > 0) {
                for (let i = 0; i < parsedEntry.length; i++) {
                    if (parsedEntry[i].trim().length > 0) {
                        vm.notificationEmails.push(parsedEntry[i].trim());
                    }
                }
            }
            vm.notificationEmails = vm._filterDuplicates(vm.notificationEmails);
            vm.notificationEmails.sort();
            vm.enteredEmails = '';
        }
    }

    removeEmail(index: Number) {
        let vm = this;
        
        vm.notificationEmails.splice(index, 1);
    }

    save() {
        let vm = this;
        
        // these are stored in the db column as a pipe-delimited string, with pipes on the beginning and end just for fun
        var emailString = vm.notificationEmails.join('|');
        emailString = '|' + emailString + '|';

        vm.$mdDialog.hide({
            emails: emailString,
        });
    }

    disableSave() {
        let vm = this;
        
        return angular.equals(vm.originalEmails, vm.notificationEmails);
    }

    /* IMPLEMENTATION : PRIVATE */
    // All private methods should start with '_' in order to distinguish them

    _filterDuplicates(passedEmails:any) {
        let checkedEmails = Object.create(null);
        for (let i = 0; i < passedEmails.length; i++) {
            let email = passedEmails[i];
            if (email in checkedEmails) {
                passedEmails.splice(i, 1);
                i--;
            }
            checkedEmails[email] = true;
        }
        return passedEmails;
    }

    $onInit() {
        let vm = this;

        // Initialize this controller
        if (vm.emails && vm.emails.length > 0) {
            var parsedEmails = vm.emails.split('|');
            // the DB value has pipes on the front and end of the string, so we'll end up with at least
            // a couple of blank array items.  Let's take those out.
            for (let i = 0; i <= parsedEmails.length; i++) {
                if (parsedEmails[i] && parsedEmails.length > 0) {
                    vm.notificationEmails.push(parsedEmails[i]);
                }
            }
        }
        // sort alphabetically
        vm.notificationEmails.sort();
        vm.originalEmails = angular.copy(vm.notificationEmails);
    }
}
