/*
 * Comcast CONFIDENTIAL
 *
 * Copyright 2003 - 2017 Comcast Corporation
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Comcast Corporation and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to Comcast Corporation
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is unlawful and strictly forbidden unless prior written permission is obtained
 * from Comcast Corporation.
 */

//// CONTROLLER ////
export class UnavailableDestinationsDialogController {
    static get $inject() {
        return ['$state', '$mdDialog', 'spotlessGroups'];
    }

    constructor(
        public $state: any,
        public $mdDialog: any,
        public spotlessGroups: any
    ) {
        /* BINDABLE: DATA */
    }

    //START FUNCTION BLOCK
    cancel() {
        let vm = this;
        vm.$mdDialog.cancel();
    }

    submit() {
        let vm = this;
        vm.$mdDialog.hide(true);
    }

    $onInit() {
        //Do init things
    }
}
