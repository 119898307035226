/*
 * Comcast CONFIDENTIAL
 *
 * Copyright 2003 - 2017 Comcast Corporation
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Comcast Corporation and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to Comcast Corporation
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is unlawful and strictly forbidden unless prior written permission is obtained
 * from Comcast Corporation.
 */

declare var angular: angular.IAngularStatic;

//// CONTROLLER ////
export class EcnDialogController {
    public originalOrder: any;

    static get $inject() {
        return ['$mdDialog', 'order'];
    }

    constructor(
        public $mdDialog: any,
        public order: any
    ) {
        let vm = this;

        /* BINDABLE: DATA */
        vm.originalOrder = angular.copy(order);
    }

    //////////////////////
    //START FUNCTION BLOCK
    //////////////////////
    cancel() {
        let vm = this;
        vm.order.deliverThroughEcnFlag = vm.originalOrder.deliverThroughEcnFlag;
        vm.order.confirmThroughEcnFlag = vm.originalOrder.confirmThroughEcnFlag;
        vm.$mdDialog.cancel();
    }

    ok() {
        let vm = this;
        vm.$mdDialog.hide(true);
    }

    checkStatus(orderStatuses:any, included:any) {
        let vm = this;
        let retValue = true;
        // default to included = true if not passed in
        included = included === undefined ? true : included;
        // if included is true, check to see if statuses are IN the passed-in array.
        // if false, check to see if statuses are NOT in the passed-in array.
        if (orderStatuses && orderStatuses.length > 0 && vm.order && vm.order.deliveryStatus) {
            retValue = included
                ? orderStatuses.indexOf(vm.order.deliveryStatus) >= 0
                : orderStatuses.indexOf(vm.order.deliveryStatus) < 0;
        }
        return retValue;
    }

    $onInit() {
        //Do init things
    }
}
