/*
 * Comcast CONFIDENTIAL
 *
 * Copyright 2003 - 2017 Comcast Corporation
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Comcast Corporation and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to Comcast Corporation
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is unlawful and strictly forbidden unless prior written permission is obtained
 * from Comcast Corporation.
 */

declare var angular: angular.IAngularStatic;

//// CONTROLLER ////
export class CopyOrderDialogController {
    // Private values

    //Bindable
    public options: any = {
        copyOrderGroups: true,
        copySpots: true,
        copyDestinations: true,
        copyTraffic: true,
        copyEmailNotifications: false,
    };
    public accountId: any;
    public isFranchise: any;
    public missingAdvertiserOrBrand: any;
    public searchAgency: string = '';
    public searchAdvertiser: string = '';
    public searchBrand: string = '';

    static get $inject() {
        return [
            '$mdDialog',
            'AgencyResourceFactory',
            'AdvertiserResourceFactory',
            'BrandsResourceFactory',
            'loginService',
            'order',
        ];
    }

    constructor(
        public $mdDialog: any,
        public AgencyResourceFactory: any,
        public AdvertiserResourceFactory: any,
        public BrandsResourceFactory: any,
        public loginService: any,
        public order: any
    ) {
        /* PRIVATE : DATA */
        //Declare all private variables here
        /*
         * This is the `vm` object. It is a direct reference to the controller
         * which acts as our 'view-model' in angular. It also limits our need
         * to be accessing $scope directly. */
        /*jshint validthis:true*/
        let vm = this;

        /* BINDABLE : DATA */
        vm.accountId = loginService.getSessionData().accountId;
        vm.isFranchise = loginService.getSessionData().franchiseCustomer;
        vm.missingAdvertiserOrBrand = !order.advertiserId || !order.brandId;
    }

    $onInit() {
        let vm = this;

        // Copy over the existing order values
        vm.options.agencyId = vm.order.agencyId;
        vm.options.advertiserId = vm.order.advertiserId;
        vm.options.brandId = vm.order.brandId;

        // Retrieve the actual names from the ids
        vm.AgencyResourceFactory.get({ id: vm.options.agencyId }).subscribe(
            function (agency: any) {
                vm.searchAgency = agency.name;
            }
        );
        
        vm.AdvertiserResourceFactory.get({ id: vm.options.advertiserId }).subscribe(
            (advertiser:any) => {
                vm.searchAdvertiser = advertiser.name;
            }
        );
        vm.BrandsResourceFactory.get({ id: vm.options.brandId }).subscribe(
            (brand:any) => {
                vm.searchBrand = brand.name;
            }
        );
    }

    /* IMPLEMENTATION : BINDABLE */
    deselectAll() {
        let vm = this;

        if (vm.options.copyOrderGroups === false) {
            vm.options.copySpots = false;
            vm.options.copyDestinations = false;
            vm.options.copyTraffic = false;
        }
    }

    checkParent(curVal:boolean) {
        if (curVal) {
            this.options.copyOrderGroups = true;
        }
    }

    cancel() {
        this.$mdDialog.cancel();
    }

    copy() {
        this.$mdDialog.hide(this.options);
    }

    loadAgencies(nameSearch:string) {
        let vm = this;

        return new Promise((resolve) => {
            vm.AgencyResourceFactory.getAll(
                {
                    providerAccountId: vm.accountId,
                    name: nameSearch,
                    limit: 15,
                }).subscribe(
                    (agencies: any) => {
                        resolve(agencies.rows);
                    },
                    (err: any) => {
                        resolve([]);
                        console.debug(err);
                    }
                );
        });
    }

    selectedAgency(agency:any) {
        let vm = this;

        if ((agency && parseInt(agency.id, 10) !== parseInt(vm.options.agencyId, 10)) || agency == null) {
            vm.searchAdvertiser = '';
            vm.options.advertiserId = null;
            vm.searchBrand = '';
            vm.options.brandId = null;
        }

        vm.options.agencyId = agency ? agency.id : null;

        if (vm.searchAgency === '') {
            vm.options.agencyId = null;
            vm.searchAdvertiser = '';
            vm.options.advertiserId = null;
            vm.searchBrand = '';
            vm.options.brandId = null;
        }
    }

    loadAdvertisers(searchName:string) {
        let vm = this;

        return new Promise((resolve) => {
            vm.AdvertiserResourceFactory.getAll(
                {
                    agencyId: vm.options.agencyId ? vm.options.agencyId : null,
                    providerAccountId: vm.accountId,
                    name: searchName,
                    limit: 15,
                }).subscribe(
                    (advertisers:any) => {
                        advertisers.sort(function (a:any, b:any) {
                            return a.name - b.name;
                        });
                        resolve(advertisers);
                    },
                    () => {
                        resolve([]);
                    }
                );
        });
    }

    selectedAdvertiser(advertiser:any) {
        let vm = this;

        if (
            (advertiser && advertiser.id != vm.options.advertiserId) ||
            advertiser == null
        ) {
            vm.searchBrand = '';
            vm.options.brandId = null;
        }

        vm.options.advertiserId = advertiser ? advertiser.id : null;

        if (vm.searchAdvertiser == '') {
            vm.options.advertiserId = null;
            vm.searchBrand = '';
            vm.options.brandId = null;
        }
    }

    loadBrands(searchName:string) {
        let vm = this;

        return new Promise((resolve) => {
            if (vm.options.advertiserId) {
                vm.BrandsResourceFactory.getAll(
                    {
                        advertiserId: vm.options.advertiserId,
                        name: searchName,
                        limit: 15,
                    }).subscribe(
                        (brands:any) => {
                            resolve(CopyOrderDialogController._filterDuplicates(brands));
                        },
                        () => {
                            resolve([]);
                        }
                    );
            } else {
                resolve([]);
                vm.options.brandId = null;
            }
        });
    }

    selectedBrand(brand:any) {
        let vm = this;

        vm.options.brandId = brand ? brand.id : null;

        if (vm.searchBrand == '') {
            vm.options.brandId = null;
        }
    }

    disableCopy() {
        let vm = this;

        let advertiserExists = vm.options.advertiserId || vm.order.advertiserId;
        let brandExists = vm.options.brandId || vm.order.brandId;
        let poNumExists = vm.options.poNumber || vm.order.poNumber;

        if (!advertiserExists || !brandExists) {
            return true;
        } else {
            return !poNumExists;
        }
    }

    /* IMPLEMENTATION : PRIVATE */
    // All private methods should start with '_' in order to distinguish them
    static _filterDuplicates(data:any) {
        if (angular.isArray(data)) {
            let result = [];
            let key:any = {};
            for (let i = 0; i < data.length; i++) {
                let val = data[i];
                if (angular.isUndefined(key[val.name])) {
                    key[val.name] = val;
                    result.push(val);
                }
            }
            if (result.length > 0) {
                return result;
            }
        }
        return data;
    }
}
