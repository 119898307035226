/*
 * Comcast CONFIDENTIAL
 *
 * Copyright 2003 - 2021 Comcast Corporation
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Comcast Corporation and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to Comcast Corporation
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is unlawful and strictly forbidden unless prior written permission is obtained
 * from Comcast Corporation.
 */

let VASTPlayer = require('vast-player');

//// CONTROLLER ////
export class VastXmlDialogController {
    private player: any;

    //Bindable
    public xmlToDisplay: string;
    public apiURL: string;
    public xmlLink: string;
    public mediaReady: boolean;
    public mediaLoadFailed: boolean;
    public videoElement: any;

    static get $inject() {
        return [
            '$scope',
            '$state',
            '$mdDialog',
            'AssetResourceFactory',
            'NotificationService',
            'filePath',
            'contentUuid',
            'vastUrl'
        ];
    }

    constructor(
        public $scope: any,
        public $state: any,
        public $mdDialog: any,
        public AssetResourceFactory: any,
        public NotificationService: any,
        public filePath: string,
        public contentUuid: string,
        public vastUrl: string
    ) {
        /* BINDABLE: DATA */
        this.xmlToDisplay = '';
        this.apiURL = '';
        this.vastUrl = vastUrl;
        this.xmlLink = '';
        this.mediaReady = false;
        this.mediaLoadFailed = false;
        this.videoElement = null;
    }

    $onInit() {
        let vm = this;
        vm.AssetResourceFactory.getVastXml(
            { filePath: vm.filePath, uuid: vm.contentUuid },
            function success(response: any) {
                vm.xmlToDisplay = response.data.xml;
                vm.apiURL = response.data.copyUrl;
                vm.loadAd();
            },
            function failure(err: object) {
                console.error(err);
            }
        );
    }

    copyXml() {
        let vm = this;
        let copyXml = (<HTMLInputElement>document.getElementById('vastXml'));
        if (copyXml) {
            copyXml.value = vm.xmlToDisplay;
            copyXml.select();
            document.execCommand('Copy');
            vm.NotificationService.showNotificationToast(
                'Copied VAST XML'
            );
        } else {
            vm.NotificationService.showNotificationToast(
                'Could not copy VAST XML'
            );
        }
    }

    copyUrl() {
        let vm = this;
        var copyUrl = (<HTMLInputElement>document.getElementById('vastUrl'));
        if (copyUrl) {
            copyUrl.value = vm.vastUrl;
            copyUrl.select();
            document.execCommand('Copy');
            vm.NotificationService.showNotificationToast(
                'Copied VAST XML link'
            );
        } else {
            vm.NotificationService.showNotificationToast(
                'Could not copy VAST XML link'
            );
        }
    }

    close() {
        let vm = this;
        vm.$mdDialog.hide();
    }

    loadAd(){
        let vm = this;

        vm.player = new VASTPlayer(document.getElementById('vastPlayer'));
        vm.player.load(
            vm.vastUrl
        ).then(function startAd() {
            vm.mediaReady = true;
            vm.$scope.$apply();
            vm.player.startAd().then(() => {
                vm.player.pauseAd();
                vm.initializePlayerElement();
            });
        }).catch(function(reason: any) {
            vm.mediaLoadFailed = true;
            vm.$scope.$apply();
            console.error('Media Loading Error: ', reason);
        });
    }

    initializePlayerElement() {
        let vm = this;
        vm.videoElement = document.getElementsByTagName("video")[0];
        vm.videoElement.controls = true;
        vm.videoElement.onended = () => {
            vm.loadAd();
        }
    }
}
