/*
 * Comcast CONFIDENTIAL
 *
 * Copyright 2003 - 2017 Comcast Corporation
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Comcast Corporation and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to Comcast Corporation
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is unlawful and strictly forbidden unless prior written permission is obtained
 * from Comcast Corporation.
 */

//// COMPONENT DIRECTIVE ////
export const metadataDialog = {
    selector: 'metadataDialog',
    bindings: {},
    template: require('./metadataDialog-template.html'),
    controller: class MetadataDialogController {
        public accountId: any;
        public agencies: any;
        public advertisers: any;
        public disableSubmit: any;

        static get $inject() {
            return [
                '$state',
                '$mdDialog',
                'AgencyResourceFactory',
                'AdvertiserResourceFactory',
                'loginService',
                'selectedSpots',
            ];
        }

        constructor(
            public $state: any,
            public $mdDialog: any,
            public AgencyResourceFactory: any,
            public AdvertiserResourceFactory: any,
            public loginService: any,
            public selectedSpots: any
        ) {
            let vm = this;
            /* BINDABLE: DATA */
            vm.accountId = vm.loginService.getSessionData().accountId;
            vm.agencies = [];
            vm.advertisers = [];
            vm.disableSubmit = true;
        }

        //START FUNCTION BLOCK
        cancel() {
            let vm = this;
            vm.$mdDialog.cancel();
        }

        submit() {
            let vm = this;
            vm.$mdDialog.hide(vm.selectedSpots);
        }

        checkSubmit() {
            let vm = this;
            vm.selectedSpots.map((item:any) => {
                vm.disableSubmit = !(item.agencyId && item.advertiserId);
                return item;
            });
        }

        loadAdvertiser(agencyId:any) {
            let vm = this;
            vm.AdvertiserResourceFactory.getAll(
                { agencyId }
            ).subscribe(
                (advertisers:any) => {
                    vm.advertisers = advertisers;
                },
                function failure(err:any) {
                    console.log(err);
                }
            );
        }

        $onInit() {
            let vm = this;
            //Do init things
            vm.AgencyResourceFactory.getAll({}).subscribe(
                function success(agencies:any) {
                    vm.agencies = agencies.rows;
                },
                function failure(err:any) {
                    console.log(err);
                }
            );
        }
    },
    controllerAs: 'vm',
};
